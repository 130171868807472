<template>
  <div>
    <div v-if="progressStore.progress !== true">
      <!--Title Bar-->
      <title-bar
        :title-value="
          providersStore.provider.d_licenseNumber + ' - ' + providersStore.provider.d_nameLicense
        "
      />

      <!--Function Bar-->
      <provider-function-bar />

      <v-container fluid class="pa-0">
        <v-row dense>
          <v-col cols="12" sm="12">
            <teacher-reviews :key="providersStore.provider.id" :teacherReviewSection="2" />
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mixin } from "@/mixins/mixin.js";
import TitleBar from "@/components/TitleBar";
import ProviderFunctionBar from "@/components/ProviderFunctionBar";
import TeacherReviews from "@/components/TeacherReviews";
import TextDisplay from "@/components/TextDisplay";
import ProviderEntryPayments from "@/components/ProviderEntryPayments";

export default {
  name: "ProviderPayments",
  mixins: [mixin],
  computed: {
    ...mapState({
      providersStore: state => state.providersStore,
      progressStore: state => state.progressStore
    })
  },
  components: {
    TitleBar,
    ProviderFunctionBar,
    TextDisplay,
    ProviderEntryPayments,
    TeacherReviews
  },
  created() {
    this.initialize();
  },
  data() {
    return {
      progress: true
    };
  },
  methods: {
    async initialize() {
      this.$store.dispatch("progressStore/set", true);
      await this.$store.dispatch("providersStore/read", this.$route.params.providerUUID);
      this.$store.dispatch("progressStore/set", false);
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
